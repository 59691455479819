Vue.component('search', {
    props: {
        name_field: String,
        value: String,
        type: String
    },
    //template: "<a v-on:click=\"$emit('change_sort', name_field)\" :order=order title='Сортировать'>{{title}}<i></i></a>"
    template: `
        <div class="flex">
        <input            
          class="form-control" :type='type'
          v-bind:value="value"
          v-on:input="$emit('input', $event.target.value, name_field)"
        >   
          <i class="fa fa-times icon-del" title="Очистить" v-on:click="$emit('clear_input', name_field)"></i>    
      </div>
    `
    //template: '<h3>ARA</h3>'
});